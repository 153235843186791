import React, { useState, useEffect } from 'react';

import {
  Box, Popover, PopoverTrigger, IconButton, PopoverContent, PopoverHeader, PopoverArrow, PopoverBody,
  Button, ButtonGroup, Slider, SliderTrack, SliderFilledTrack, SliderThumb,
  NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
  Tooltip, Flex
} from '@chakra-ui/react';

import { AiOutlinePlayCircle } from 'react-icons/ai'
import { VscDebugAlt, VscDebugStart } from "react-icons/vsc";
import { BiShow, BiHide } from 'react-icons/bi'
import { BsDice3 } from 'react-icons/bs'
import { FaRandom } from 'react-icons/fa'

/**
 * GeneratePopover component.
 * 
 * @component
 * @param {boolean} isGenerating - Flag indicating if generation is in progress.
 * @returns {JSX.Element} GeneratePopover component.
 */
export const GeneratePopover = ({
  isGenerating = false,
  data = {},
  onChange = () => console.warn("No GeneratePopover onChange handler."),
  onClick = console.warn("No GeneratePopover onClick handler.")
}) => {
  const p = localStorage.getItem("private") === "true"
  const [isPrivate, setIsPrivate] = useState(p)
  const [priority, setPriority] = useState("medium")
  const [batchSize, setBatchSize] = useState(3)
  const [max, setMax] = useState(5)

  useEffect(() => {
    let m = 5
    if (priority === "low") m = 50
    if (priority === "medium") m = 10
    if (priority === "high") m = 5
    setMax(m)
    if (batchSize > m) setBatchSize(m)
  }, [priority, batchSize])

  return (
    // Wrapper box required to suppress console spam (https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911)
    <Box>
      <Popover>
        <PopoverTrigger><span>
          <Tooltip hasArrow openDelay={250} label="Generate...">
            <IconButton
              isLoading={isGenerating}
              icon={<AiOutlinePlayCircle />}
              colorScheme="green"
              variant={'ghost'}
            />
          </Tooltip>
        </span></PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Generate</PopoverHeader>
          <PopoverArrow />
          <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
            <Flex w={'full'} direction={'column'} gap={2}>
              {/* Private toggle and Priority */}
              <ButtonGroup w={'full'}>
                <Tooltip
                  hasArrow
                  label={isPrivate ? 'Parameters are hidden' : 'Parameters are visible'}
                >
                  <IconButton
                    style={{ margin: 0 }}
                    icon={isPrivate ? <BiHide /> : <BiShow />}
                    size={'sm'}
                    variant={'ghost'}
                    colorScheme={'blue'}
                    onClick={(e) => {
                      localStorage.setItem("private", !isPrivate)
                      setIsPrivate(!isPrivate);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Priority of job"
                  openDelay={250}
                >
                  <ButtonGroup
                    ml={2}
                    flex={1}
                    colorScheme="blue"
                    size="sm"
                    isAttached
                    variant="outline"
                    spacing={'0px'}
                    w={'100%'}
                  >
                    <Button
                      flex={1}
                      style={{ margin: 0 }}
                      variant={priority === 'low' ? 'solid' : 'outline'}
                      onClick={() => {
                        setPriority('low');
                      }}
                    >
                      Low
                    </Button>
                    <Button
                      flex={1}
                      style={{ margin: 0 }}
                      variant={priority === 'medium' ? 'solid' : 'outline'}
                      onClick={() => {
                        setPriority('medium');
                      }}
                    >
                      Medium
                    </Button>
                    <Button
                      flex={1}
                      style={{ margin: 0 }}
                      variant={priority === 'high' ? 'solid' : 'outline'}
                      onClick={() => {
                        setPriority('high');
                      }}
                    >
                      High
                    </Button>
                  </ButtonGroup>
                </Tooltip>
              </ButtonGroup>
              {/* Seed Controls */}
              <Flex w={'full'}>
                <Tooltip
                  hasArrow
                  label="Random seed number used to generate the initial random noise which results in a different image."
                  openDelay={250}
                >
                  <NumberInput
                    allowMouseWheel
                    value={data.params.mainPass.sampler.seed}
                    min={-1}
                    max={2 ** 32}
                    clampValueOnBlur={true}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(v) => {
                      onChange({
                        ...data,
                        params: {
                          ...data.params,
                          mainPass: {
                            ...data.params.mainPass,
                            sampler: {
                              ...data.params.mainPass.sampler,
                              seed: parseInt(v),
                            },
                          },
                        },
                      });
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Random (sequential)"
                >
                  <IconButton
                    variant={'ghost'}
                    onClick={() => {
                      let r = Math.floor(Math.random() * 2 ** 32);
                      onChange({
                        ...data,
                        params: {
                          ...data.params,
                          mainPass: {
                            ...data.params.mainPass,
                            sampler: {
                              ...data.params.mainPass.sampler,
                              seed: parseInt(r),
                            },
                          },
                        },
                      });
                    }}
                    icon={<BsDice3 />}
                    style={{ margin: '0px' }}
                  />
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Random (non-sequential)"
                >
                  <IconButton
                    variant={'ghost'}
                    onClick={() => {
                      let r = -1;
                      onChange({
                        ...data,
                        params: {
                          ...data.params,
                          mainPass: {
                            ...data.params.mainPass,
                            sampler: {
                              ...data.params.mainPass.sampler,
                              seed: parseInt(r),
                            },
                          },
                        },
                      });
                    }}
                    icon={<FaRandom />}
                    style={{ margin: '0px' }}
                  />
                </Tooltip>
              </Flex>
              <Flex w={'full'}>
                <Tooltip
                  hasArrow
                  label={`Generate 1 image`}
                >
                  <Button
                    w={'full'}
                    size={'sm'}
                    leftIcon={<VscDebugStart />}
                    colorScheme="green"
                    variant={'solid'}
                    isLoading={isGenerating}
                    onClick={(e) => {
                      onClick(data, {
                        batchSize: 1,
                        priority,
                        isPrivate,
                        debug: false
                      });
                    }}
                  >
                    Generate
                  </Button>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label={`Generate 1 image in Debug mode.  This will generate a single image and return debug information such as intermediate image results for complex jobs.`}
                >
                  <Button
                    w={'full'}
                    size={'sm'}
                    leftIcon={<VscDebugAlt />}
                    colorScheme="green"
                    variant={'solid'}
                    isLoading={isGenerating}
                    onClick={(e) => {
                      onClick(data, {
                        batchSize: 1,
                        priority,
                        isPrivate,
                        debug: true
                      });
                    }}
                  >
                    Debug
                  </Button>
                </Tooltip>
              </Flex>
              <Flex w={'full'}>
                <Slider
                  value={batchSize}
                  step={1}
                  min={1}
                  max={max}
                  onChange={(v) => {
                    setBatchSize(v);
                  }}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>

                  <Tooltip
                    hasArrow
                    label="Batch size"
                    openDelay={250}
                  >
                    <SliderThumb />
                  </Tooltip>
                </Slider>
                <Tooltip
                  hasArrow
                  label={`Generate ${batchSize} More`}
                >
                  <Button
                    size={'sm'}
                    leftIcon={<AiOutlinePlayCircle />}
                    colorScheme="green"
                    variant={'solid'}
                    isLoading={isGenerating}
                    onClick={(e) => {
                      onClick(data, {
                        isPrivate,
                        batchSize,
                        priority,
                      });
                    }}
                  >
                    x{batchSize}
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};